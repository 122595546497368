<template>
  <v-dialog :value="value" @input="close" max-width="80%" >
    <v-card
      min-height="400"
      style="background-image: url('/img/subscribe_bg.png') !important;
             background-repeat: no-repeat !important;
             background-size: 100% 100% !important;
             border-radius: 30px !important;">

      <v-row no-gutters align="center">

        <!-- Title + Close icon -->
        <v-col cols="12" class="mt-6">
          <v-row no-gutters align="center">
            <v-col cols="8" class="offset-2">
              <v-img src="/img/roote-logo-white.png" height="80" contain/>
            </v-col>
            <v-col cols="1" class="offset-1">
              <v-btn icon @click="close(false)" style="background-color: white !important;">
                <v-icon color="primary">fal fa-times</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <!-- Card advantages -->
        <v-col v-if="step === 1" cols="10" md="4" offset-md="4" offset="1" class="mt-10">
          <v-card
            height="150"
            style="border-radius: 20px !important;background-color: rgba(255, 255, 255, 0.9) !important;">

            <v-row no-gutters>
              <template v-for="item of $t('account.profile.subscribeDialog.advantages')">
                <v-col cols="12" class="mt-3">
                  <v-row no-gutters justify="center">
                    <v-col cols="2">
                      <span class="float-right">{{item.icon}}</span>
                    </v-col>
                    <v-col cols="10">
                      <span class="font-weight-bold ml-2" style="color: #cf3435 !important;">{{item.text}}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </template>

            </v-row>
          </v-card>
        </v-col>

        <!-- Plans -->
        <v-col v-if="step === 1" cols="10" md="6" offset-md="3" offset="1" class="mt-6">
          <v-row no-gutters justify="center" align="center">

            <!-- Lifetime -->
            <v-card
              @click="selectedPlan = offers.PREMIUM_LIFETIME"
              :height="selectedPlan.NAME === offers.PREMIUM_LIFETIME.NAME ? '220' : '120'"
              :width="selectedPlan.NAME === offers.PREMIUM_LIFETIME.NAME ? '150' : '120'"
              :style="selectedPlan.NAME === offers.PREMIUM_LIFETIME.NAME ? 'border-radius:10px !important;border: 2px solid #4D59FF !important;' : 'border-top-left-radius: 10px !important;border-bottom-left-radius: 10px !important;border-top: 2px solid #4D59FF !important;border-left: 2px solid #4D59FF !important;border-bottom: 2px solid #4D59FF !important;'"
              style="background-color: rgba(255, 255, 255, 0.9) !important;"
            >
              <v-row align="center" justify="center" no-gutters style="height: 100% !important;">
                <v-col cols="12" style="">
                  <h3 class="text-center font-weight-bold ma-0">{{offers.PREMIUM_LIFETIME.PRICE}}€</h3>
                  <h3 class="text-center font-weight-bold ma-0">{{$t('account.profile.subscribeDialog.lifetime_duration')}}</h3>
                </v-col>
              </v-row>
            </v-card>

            <!-- Annually -->
            <v-card
              @click="selectedPlan = offers.PREMIUM_ANNUALLY"
              :height="selectedPlan.NAME === offers.PREMIUM_ANNUALLY.NAME ? '220' : '120'"
              :width="selectedPlan.NAME === offers.PREMIUM_ANNUALLY.NAME ? '150' : '120'"
              :style="selectedPlan.NAME === offers.PREMIUM_ANNUALLY.NAME ? 'border-radius: 10px !important;border: 3px solid #4D59FF !important;' : 'border: 2px solid #4D59FF !important;'"
              style="background-color: rgba(255, 255, 255, 0.9) !important;"
            >
              <v-row align="center" justify="center" no-gutters style="height: 100% !important;">
                <v-col cols="12">
                  <h3 class="text-center font-weight-bold" style="color: #4D59FF !important;">Populaire</h3>
                </v-col>
                <v-col cols="12">
                  <h3 class="text-center font-weight-bold ma-0">{{offers.PREMIUM_ANNUALLY.PRICE}}€</h3>
                  <h3 class="text-center font-weight-bold ma-0">{{$t('account.profile.subscribeDialog.annually_duration')}}</h3>
                </v-col>
                <v-col cols="12" v-if="selectedPlan.NAME === offers.PREMIUM_ANNUALLY.NAME">
                  <p class="text-center ma-0" style="font-size: 12px !important;">
                    Total de <br>
                    <span class="font-weight-bold" style="font-size: 14px !important;">{{parseFloat(offers.PREMIUM_ANNUALLY.PRICE/12).toFixed(2)}}€</span><br>
                    Par mois
                  </p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Monthly -->
            <v-card
              @click="selectedPlan = offers.PREMIUM_MONTHLY"
              :height="selectedPlan.NAME === offers.PREMIUM_MONTHLY.NAME ? '220' : '120'"
              :width="selectedPlan.NAME === offers.PREMIUM_MONTHLY.NAME ? '150' : '120'"
              :style="selectedPlan.NAME === offers.PREMIUM_MONTHLY.NAME ? 'border-radius:10px !important;border: 2px solid #4D59FF !important;' : 'border-top-right-radius: 10px !important;border-bottom-right-radius: 10px !important;border-top: 2px solid #4D59FF !important;border-right: 2px solid #4D59FF !important;border-bottom: 2px solid #4D59FF !important;'"
              style="background-color: rgba(255, 255, 255, 0.9) !important;"
            >
              <v-row align="center" justify="center" no-gutters style="height: 100% !important;">
                <v-col cols="12" style="">
                  <h3 class="text-center font-weight-bold ma-0">{{offers.PREMIUM_MONTHLY.PRICE}}€</h3>
                  <h3 class="text-center font-weight-bold ma-0">{{$t('account.profile.subscribeDialog.monthly_duration')}}</h3>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-col>

        <!-- Subscribe btn -->
        <v-col v-if="step === 1" cols="12" class="mt-6 text-center">
          <v-btn color="white" class="mx-auto" width="180" style="border-radius: 10px !important;" @click="goStep2">
            <span style="color: #4D59FF !important;font-size: 16px !important;" class="font-weight-bold text-uppercase">
              {{$t('account.profile.subscribeDialog.subscribe-btn')}}
            </span>
          </v-btn>
        </v-col>

        <!-- Text -->
        <v-col v-if="step === 1" cols="12" class="mt-6">
          <p class="font-weight-bold text-center" style="color: white !important;" v-html="$t('account.profile.subscribeDialog.bottom-text')"></p>
        </v-col>

        <!-- Step = 2 : Billing details -->
        <v-col v-if="step === 2" cols="12" md="8" offset-md="2" class="mt-3" >
          <BillingDetailsForm
            v-if="item.subscription.billing"
            :form="form"
            :show-btn="false"
            @close="close"
            @nextStep="goStep3" />
          </v-col>

        <!-- Step 3 : Payment details -->
        <v-col cols="12" md="8" offset-md="2" class="mt-6" v-if="step === 3">
          <StripeHandler
           :item="selectedPlan"
           :amount="amount"
           @successPaid="onSuccessPaid"
           @close="$emit('close')"/>
        </v-col>

        <!-- Error -->
        <v-col cols="12" v-if="step === 2">
          <AlertBar />
        </v-col>

        <!-- Step 3 : Success -->
        <v-col cols="12" v-if="step === 4">
          <v-alert type="success">
            <span>{{$t('account.profile.subscribeDialog.success')}}</span>
          </v-alert>
        </v-col>

        <!-- Step 3 : Buttons -->
        <v-col cols="12" v-if="step === 4">
          <v-row justify="center" class="my-2 mx-2">

            <!-- Close -->
            <v-btn color="white" class="mx-auto" width="180" style="border-radius: 10px !important;" @click="close(true)">
                <span style="color: #4D59FF !important;font-size: 16px !important;" class="font-weight-bold text-uppercase">
                  {{$t('common.buttons.close')}}
                </span>
            </v-btn>

          </v-row>
        </v-col>
      </v-row>

    </v-card>

  </v-dialog>
</template>

<script>

  import dialogMixin from "@/mixins/dialog"
  import offers from "@/translations/offers.json"

  export default {
    name: "SubscribeDialog",

    components: {
      BillingDetailsForm: () => import('@/components/Account/BillingDetailsForm'),
      AlertBar: () => import("@/components/Common/AlertBar"),
      SelectCountries: () => import("@/components/Common/Inputs/SelectCountries"),
      ProgressCircle: () => import("@/components/Common/ProgressCircle"),
      StripeHandler: () => import("@/components/Account/StripeHandler")
    },

    mixins: [dialogMixin],

    data() {

      return {
        isLoadingBtn: {
          billingDetails: false, pay: false
        },

        step: 1,
        clientToken: null,
        error: false,
        selectedPlan: offers.PREMIUM_ANNUALLY,
        amount: null,
        offers
      }
    },

    created() {
      if (this.$session.get('plan') === "FREE")
        this.form = {};

      this.form = {...this.item.subscription.billing};

      //console.log(this.form);
      if (this.form.firstname === "")
        this.form.firstname = this.item.person.firstname;

      if (this.form.name === "")
        this.form.name = this.item.person.name;

      if (this.form.email === "")
        this.form.email = this.item.email;

      //console.log(this.form);
    },

    methods: {

      goStep2() {
        this.step = 2;
      },

      goStep3() {

        this.isLoadingBtn.billingDetails = true;
        this.step = 3;
        this.amount = this.selectedPlan.PRICE*100;
        //this.getClientToken();
      },

      onSuccessPaid() {

        this.isLoadingBtn.pay = true;
        this.step = 4;
      },

      onPaymentError(data) {
        this.$store.commit("alert/showError", this.$t('teams.billing.subscribeDialog.errors.PAYMENT_ERROR'))
      },

      selectPlan(plan) {
        if (this.step === 1)
          this.selectedPlan = plan;
      }
    },

    computed: {


      ThreeDSecureParams() {
        return {
          amount: this.amount ? this.amount.amount_incl_tax : 29,
          email: this.currentTeam.billing.email,
          billingAddress:
          {
            givenName: this.currentTeam.billing.name,
            surname: "",
            phoneNumber: this.currentTeam.billing.phone,
            streetAddress: this.currentTeam.billing.address.street,
            extendedAddress: this.currentTeam.billing.address.additional_street,
            locality: this.currentTeam.billing.address.locality,
            region: "",
            postalCode: this.currentTeam.billing.address.postal_code,
            countryCodeAlpha2: this.currentTeam.billing.address.country
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>